import { useDisclosure } from "@nextui-org/react";
import { ScheduleModal } from "../../components/modals/schedule/scheduleModal";
import { EmployeePage } from "./indexPages/employeePage";

export function DashboardHome() {
  const scheduleModal = useDisclosure();

  return (
    <>
      <ScheduleModal isOpen={scheduleModal.isOpen} onOpenChange={scheduleModal.onClose} />
      <EmployeePage />
    </>
  )
}