import rootStore from "../../app/store";
import crud, { ApiResponse } from "../crud"

export interface Billing {
  id: number;
  date: string;
  payer: number;
  provider: number;
  amount: number;
  paid: boolean;
  description: string;
  number: number;
}

export function BillingsAPI(baseUrl: string) {
  return {
    list: async () => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.get(`${baseUrl}/billings/?renter_id=${renterId}`, undefined, true) as ApiResponse<Billing[]>;
    },
    get: async (id: number) => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.get(`${baseUrl}/billings/${id}/?renter_id=${renterId}`, undefined, true) as ApiResponse<Billing>;
    }
  }
}