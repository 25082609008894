import { Chip, useDisclosure } from "@nextui-org/react";
import { useState, useEffect } from "react";
import { Renter } from "../../../api/renters/renters";
import { RenterModal } from "./modals/renterModal";
import { useAppSelector } from "../../../hooks/useAppSelector";

export function RenterChip({ renterId }: { renterId: number }) {
  const [item, setItem] = useState<Renter | null>(null);
  const disclosure = useDisclosure();
  const items = useAppSelector(state => state.handbooks.renter);

  useEffect(() => {
    setItem(items.find(el => el.id == renterId) || null);
  }, [renterId, items]);

  return (
    <>
      <RenterModal isOpen={disclosure.isOpen} onOpenChange={disclosure.onOpenChange} item={item!} />
      {item !== null && (
        <Chip className="cursor-pointer" onClick={disclosure.onOpen} variant="dot" color="primary">{item?.display_name}</Chip>
      )}
      {item == null && (
        <Chip variant="dot" color="default">н/д</Chip>
      )}
    </>
  );
};