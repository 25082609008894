import { useCallback } from 'react';
import { parseErrorDetails, generateErrorMessage } from '../utils/errorUtils';

interface ErrorDetail {
  loc: (string | number)[];
  msg: string;
}

interface FastApiErrorResponse {
  detail?: ErrorDetail[];
}

interface ErrorResponse {
  response?: {
    data?: {
      message?: string;
    };
    status?: number;
  };
  request?: any;
  message?: string;
  detail?: ErrorDetail[];
}

const useErrorHandling = () => {
  const handleError = useCallback((error: ErrorResponse): { parsedErrors?: { field: string; message: string }[]; errorMessage: string } => {
    if (error.detail) {
      const parsedErrors = parseErrorDetails(error as FastApiErrorResponse);
      const errorMessage = generateErrorMessage(error as FastApiErrorResponse);
      return {
        parsedErrors,
        errorMessage
      };
    }

    if (error.response) {
      if (error.response.data && error.response.data.message) {
        return { errorMessage: error.response.data.message };
      }
      return { errorMessage: `Серверная ошибка: ${error.response.status}` };
    } else if (error.request) {
      return { errorMessage: 'Нет ответа от сервера' };
    } else {
      return { errorMessage: error.message || 'Неизвестная ошибка' };
    }
  }, []);

  return handleError;
};

export default useErrorHandling;