import rootStore from "../../app/store";
import crud, { ApiResponse } from "../crud"

export interface Office {
  id: number;
  display_name: string;
  office_type: number;
  area: number;
  price: number;
  room_count: number;
  location: number;
  floor: number;
  office_lock: number;
}

export function OfficiesAPI(baseUrl: string) {
  return {
    list: async () => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.get(`${baseUrl}/offices/?renter_id=${renterId}`, undefined, true) as ApiResponse<Office[]>;
    },
    get: async (id: number) => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.get(`${baseUrl}/offices/${id}/?renter_id=${renterId}`, undefined, true) as ApiResponse<Office>;
    }
  }
}