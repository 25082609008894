import rootStore from "../../app/store";
import crud, { ApiResponse } from "../crud"

export interface Ticket {
  id: number,
  title: string,
  description: string,
  status: string,
  category: number,
  office: number,
  user: number,
  created_at: string,
  updated_at: string
}

interface TicketCreate {
  title: string;
  description: string;
  status: string;
  category: number;
  office: number;
  user: number;
}

export interface TicketMessage {
  id: number;
  ticket_id: number;
  message: string;
  sender: string;
  created_at: string;
}

interface CreateTicketMessage {
  message: string;
  sender: string;
  ticket_id: string;
}

export interface TicketCategory {
  id: number;
  display_name: string;
}

export function TicketsAPI(baseUrl: string) {
  return {
    list: async () => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.get(`${baseUrl}/tickets/?renter_id=${renterId}`, undefined, true) as ApiResponse<Ticket[]>;
    },
    get: async (id: number) => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.get(`${baseUrl}/tickets/${id}/?renter_id=${renterId}`, undefined, true) as ApiResponse<Ticket>;
    },
    create: async (userData: TicketCreate) => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.post(`${baseUrl}/tickets/?renter_id=${renterId}`, userData, true) as ApiResponse<Ticket>;
    },
    close: async (id: number) => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.post(`${baseUrl}/tickets/${id}/close/?renter_id=${renterId}`, undefined, true) as ApiResponse<Ticket>;
    },
    messages: {
      list: async (id: number) => {
        const renterId = rootStore.getState().location.location.id;
        return await crud.get(`${baseUrl}/tickets/${id}/messages/?renter_id=${renterId}`, undefined, true) as ApiResponse<TicketMessage[]>;
      },
      create: async (id: number, messageData: CreateTicketMessage) => {
        const renterId = rootStore.getState().location.location.id;
        return await crud.post(`${baseUrl}/tickets/${id}/messages/?renter_id=${renterId}`, messageData, true) as ApiResponse<TicketMessage>;
      }
    },
    categories: {
      list: async () => {
        const renterId = rootStore.getState().location.location.id;
        return await crud.get(`${baseUrl}/tickets/categories/?renter_id=${renterId}`, undefined, true) as ApiResponse<TicketCategory[]>;
      },
      get: async (id: number) => {
        const renterId = rootStore.getState().location.location.id;
        return await crud.get(`${baseUrl}/tickets/categories/${id}/?renter_id=${renterId}`, undefined, true) as ApiResponse<TicketCategory>;
      }
    }
  }
}
