import rootStore from "../../app/store";
import crud, { ApiResponse } from "../crud"

export interface SharedLock {
  id: number,
  display_name: string,
  lock_system: string,
  lock_service_id: string
}

export function SharedLocksAPI(baseUrl: string) {
  return {
    get: async () => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.get(`${baseUrl}/shared-locks/?renter_id=${renterId}`, undefined, true) as ApiResponse<SharedLock[]>;
    },
    getByLocation: async (locationId: number) => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.get(`${baseUrl}/shared-locks/location/${locationId}/?renter_id=${renterId}`, undefined, true) as ApiResponse<SharedLock[]>;
    },
    unlock: async (id: number) => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.post(`${baseUrl}/shared-locks/${id}/unlock/?renter_id=${renterId}`, {}, true) as string;
    },
    lock: async (id: number) => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.post(`${baseUrl}/shared-locks/${id}/lock/?renter_id=${renterId}`, {}, true) as string;
    }
  }
}