import { Spinner } from "@nextui-org/react";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "../../../api";
import { toast } from "react-toastify";

export function InviteHashPage() {
  const { hash } = useParams() as { hash: string };
  const navigate = useNavigate();

  useEffect(() => {
    api.auth.connectRenter(hash)
      .then(res => {
        console.log(res);
        toast.success("Вы успешно приняли приглашение!");
        navigate("/dashboard/selectLocation");
      })
      .catch(err => {
        console.log(err);
        toast.error("При принятии приглашения произошла ошибка!");
      })
  }, []);

  return (
    <>
      <div className="flex flex-col gap-2 p-2 md:p-4">
        <div className="flex flex-row gap-2 items-center">
          <span className="font-bold text-2xl">Принятие приглашения</span>
        </div>
        <div className="flex flex-col gap-2 mb-2">
          <span className="font-sm">Подождите, пытаемся принять приглашение...</span>
        </div>
        <div className="flex flex-col gap-2 mb-2">
          <Spinner />
        </div>
      </div>
    </>
  )
}