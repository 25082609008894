import { Autocomplete, AutocompleteItem, AutocompleteProps } from "@nextui-org/react";
import { UseFormRegisterReturn } from "react-hook-form";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { Office } from "../../../api/officies/officies";

export function NewOfficeHandbook(props: AutocompleteProps<Office>) {
  const items = useAppSelector(state => state.handbooks.office);

  return (
    <Autocomplete
      defaultItems={items}
      label="Офис"
      placeholder="Выберите офис"
      variant="bordered"
      {...props}
    >
      {(item) => (
        <AutocompleteItem key={String(item.id)}>
          {item.display_name}
        </AutocompleteItem>
      )}
    </Autocomplete>
  );
}

export function OfficeHandbook({ register, isInvalid, errorMessage, defaultSelectedKey }: { register: UseFormRegisterReturn, isInvalid: boolean, errorMessage: string, defaultSelectedKey?: string }) {
  const items = useAppSelector(state => state.handbooks.office);

  return (
    <Autocomplete
      {...register}
      defaultItems={items}
      label="Офис" 
      placeholder="Выберите офис"
      variant="bordered"
      isInvalid={isInvalid}
      errorMessage={errorMessage}
      defaultSelectedKey={defaultSelectedKey}
    >
      {(item) =>
        <AutocompleteItem key={String(item.id)}>{`${item.display_name}`}</AutocompleteItem>
      }
    </Autocomplete>
  )
}