import { Button, Tabs, Tab } from "@nextui-org/react";
import { Column } from "../tables/tableTypes";

export function PageBuilder({
  title,
  defaultValues,
  isSensitiveData,
  submitButtonText = "Создать",
  onSubmit,
  onDelete,
  viewEntityComponent
}: any) {
  console.log(defaultValues, isSensitiveData, onSubmit);

  return (
    <>
      <div className="p-4">
        <div className="flex flex-col justify-between mb-4">
          <div className="flex flex-col gap-4 mb-4">
            <span className="font-bold text-2xl">{title}</span>
          </div>
          <div className="flex flex-col gap-4">
            <Tabs>
              <Tab title="Просмотр информации">
                { viewEntityComponent }
              </Tab>
              <Tab title="Редактирование">
                <form>
                  <div className="flex flex-col">
                  </div>
                  <div className="flex flex-row gap-4">
                    {onDelete && (
                      <Button color="danger" variant="flat">Удалить</Button>
                    )}
                    <Button color="success" type="submit" variant="flat">{submitButtonText}</Button>
                  </div>
                </form>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </>
  );
}

export function ViewPage({ item, columns }: { item: any | null, columns: Column[] }) {
  return (
    <>
      <div className="flex flex-col">
        <div className="flex flex-col">
          { columns.map(column => {
            return (
              <div className="flex flex-col mb-4 gap-1" key={column.key}>
                <span>{ column.label }</span>
                { /* @ts-ignore */ }
                <span className="text-lg font-medium">{ renderCell(item, column) }</span>
              </div>
            )
          }) }
        </div>
      </div>
    </>
  )
}