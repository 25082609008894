import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { FaExpand } from "react-icons/fa6";
import { Button, useDisclosure } from "@nextui-org/react";
import { api } from "../../../api";
import { Office } from "../../../api/officies/officies";
import { Lock } from "../../components/lock/lock";
import { SharedLock } from "../../components/lock/sharedLock";
import { SharedLock as SharedLockType } from "../../../api/sharedLocks/sharedLocks";

export function LocksPage() {
  const [offices, setOffices] = useState<Office[]>([]);
  const [sharedLocks, setSharedLocks] = useState<SharedLockType[]>([]);

  const fullscreenDisclosure = useDisclosure();

  useEffect(() => {
    api.officies.list()
      .then(data => {
        setOffices(data.data);
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при загрузке офисов!");
      });

    api.sharedLocks.get()
      .then(data => {
        setSharedLocks(data.data);
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при загрузке общих замков!");
      });
  }, []);

  return (
    <div className="flex flex-row flex-wrap gap-4 w-full">
      <div className="flex-grow bg-white dark:bg-zinc-900 rounded-xl border-2 border-foreground-200 dark:border-foreground-100 p-4">
        <div className="flex flex-row justify-between w-full items-center mb-4">
          <div className="flex flex-row gap-4 items-center">
            <span className="font-medium">Замки</span>
            <Button variant="flat" size="sm" color="default" onClick={fullscreenDisclosure.onOpen}>
              <FaExpand />
            </Button>
          </div>
        </div>
        <div className="flex flex-col gap-2 mb-2">
          <span className="font-sm">Ниже отображены все замки, к которым у Вас есть доступ.</span>
        </div>
        <div className="flex flex-col gap-2 mb-2">
          <div className="flex flex-row flex-wrap gap-2 max-w-[720px] p-2 mt-2">
            {sharedLocks.map((lock) => (
              <SharedLock key={lock.id} lock={{ id: lock.id, displayName: lock.display_name }} />
            ))}
            {offices.map((office) => (
              <Lock key={office.id} lock={{ id: office.id, displayName: office.display_name }} />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
