import { Button, Input, Link } from "@nextui-org/react";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { api } from "../../api";
import { ToastContainer, toast } from "react-toastify";
import { motion, AnimatePresence } from "framer-motion";
import { DarkModeToggler } from "../components/darkmode/darkModeToggler";

export function LoginPage() {
  const navigate = useNavigate();
  const [displayPassword, setDisplayPassword] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      username: localStorage.getItem("username") || "",
      password: "",
    },
  });

  const onSubmit = useCallback((data: any) => {
    if (data.password.length === 0) {
      localStorage.setItem("username", data.username);
      setDisplayPassword(true);
    } else {
      api.auth.login({ username: data.username, password: data.password })
        .then((response) => {
          localStorage.clear();
          localStorage.setItem("access_token", response.access_token);
          navigate("/dashboard");
        })
        .catch((err) => {
          toast.error("Произошла ошибка при авторизации!");
          console.log(err);
        });
    }
  }, [navigate]);

  const resetUsername = () => {
    localStorage.removeItem("username");
    setValue("username", "");
    setValue("password", "");
    setDisplayPassword(false);
  };

  useEffect(() => {
    if (localStorage.getItem("username") && localStorage.getItem("username")!.length > 1) {
      setDisplayPassword(true);
    }
  }, []);

  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <div className="relative flex flex-col items-center justify-center w-full h-screen bg-[url(/Bg.png)] bg-cover bg-center">
        <div className="absolute inset-0 bg-black bg-opacity-0 dark:bg-opacity-20 transition-all"></div>
        <div className="relative z-10 flex flex-col items-center justify-center w-full">
          <motion.div
            className="bg-background rounded-xl p-8 shadow flex flex-col gap-2 md:min-w-[515px]"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.2, ease: "easeInOut" }}
          >
            <h2>Вход</h2>
            <span className="font-medium">в личный кабинет</span>
            <form onSubmit={handleSubmit(onSubmit)} className="mt-2">
              <Input
                label="Имя пользователя"
                placeholder="Введите имя пользователя"
                variant="bordered"
                isDisabled={displayPassword}
                {...register("username", { required: true })}
                className="mb-2"
                defaultValue={localStorage.getItem("username") || ""}
              />
              {errors.username && <span>Имя пользователя обязательно</span>}
              <AnimatePresence>
                {displayPassword && (
                  <motion.div
                    className="mt-0"
                    initial={{ opacity: 0, height: 0 }}
                    animate={{ opacity: 1, height: "auto" }}
                    exit={{ opacity: 0, height: 0 }}
                    transition={{ duration: 0.2, ease: "easeInOut" }}
                  >
                    <Input
                      label="Пароль"
                      placeholder="Введите ваш пароль"
                      type="password"
                      variant="bordered"
                      {...register("password", { required: true })}
                    />
                    {errors.password && <span>Пароль обязателен</span>}
                  </motion.div>
                )}
              </AnimatePresence>
              <div className="mt-4 flex gap-4">
                <AnimatePresence>
                  {displayPassword && (
                    <motion.div
                      initial={{ opacity: 0, width: 0 }}
                      animate={{ opacity: 1, width: "auto" }}
                      exit={{ opacity: 0, width: 0 }}
                      transition={{ duration: 0.2, ease: "easeInOut" }}
                    >
                      <Button
                        color="primary"
                        variant="flat"
                        className="w-full font-semibold"
                        onClick={resetUsername}
                      >
                        Это не моё имя пользователя
                      </Button>
                    </motion.div>
                  )}
                </AnimatePresence>
                <Button color="primary" className="w-full font-semibold" type="submit">
                  Войти
                </Button>
              </div>
              <div className="mt-4 flex w-full flex-col items-center">
                <span>Нет аккаунта?</span>
                <Link href="/signUp" className="font-medium" showAnchorIcon>Зарегистрируйтесь</Link>
              </div>
            </form>
          </motion.div>
          {process.env.VITE_BUILD_BRANCH?.startsWith("dev") && (
            <motion.div
              className="flex flex-col items-center gap-2 mt-4"
              initial={{ opacity: 0, scale: 0.8 }}
              animate={{ opacity: 1, scale: 1 }}
              transition={{ duration: 0.2, ease: "easeInOut" }}
            >
              <span className="text-sm text-zinc-600 font-medium">
                <a href={`https://github.com/Elasticwork-space/ElasticClient/commit/${process.env.VITE_COMMIT_HASH}`}>
                  {process.env.VITE_COMMIT_HASH} / {process.env.VITE_BUILD_BRANCH}
                </a>
              </span>
            </motion.div>
          )}
          <br />
          <DarkModeToggler collapsed={false} />
        </div>
      </div>
    </>
  );
}
