import rootStore from "../../app/store";
import crud from "../crud"

export function LocksAPI(baseUrl: string) {
  return {
    unlock: async (id: number) => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.post(`${baseUrl}/locks/${id}/unlock/?renter_id=${renterId}`, {}, true) as string;
    },
    lock: async (id: number) => {
      const renterId = rootStore.getState().location.location.id;
      return await crud.post(`${baseUrl}/locks/${id}/lock/?renter_id=${renterId}`, {}, true) as string;
    }
  }
}