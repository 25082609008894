import { Autocomplete, AutocompleteItem, AutocompleteProps } from "@nextui-org/react";
import { UseFormRegisterReturn } from "react-hook-form";
import { useAppSelector } from "../../../hooks/useAppSelector";
import { TicketCategory } from "../../../api/tickets/tickets";

export function NewCategoryHandbook(props: AutocompleteProps<TicketCategory>) {
  const items = useAppSelector(state => state.handbooks.ticketCategory);

  return (
    <Autocomplete
      defaultItems={items}
      label="Категория"
      placeholder="Выберите категорию"
      variant="bordered"
      {...props}
    >
      {(item) => (
        <AutocompleteItem key={String(item.id)}>
          {item.display_name}
        </AutocompleteItem>
      )}
    </Autocomplete>
  );
}

export function CategoryHandbook({ register, isInvalid, errorMessage, defaultSelectedKey }: { register: UseFormRegisterReturn, isInvalid: boolean, errorMessage: string, defaultSelectedKey?: string }) {
  const items = useAppSelector(state => state.handbooks.ticketCategory);

  return (
    <Autocomplete
      {...register}
      defaultItems={items}
      label="Категория"
      placeholder="Выберите категорию"
      variant="bordered"
      isInvalid={isInvalid}
      errorMessage={errorMessage}
      defaultSelectedKey={defaultSelectedKey}
    >
      {(item) =>
        <AutocompleteItem key={String(item.id)}>{`${item.display_name}`}</AutocompleteItem>
      }
    </Autocomplete>
  )
}