import { getAPIPort } from "../utils";
import { AuthAPI } from "./auth/auth";
import { BillingsAPI } from "./billings/billings";
import { LocationsAPI } from "./locations/locations";
import { LocksAPI } from "./locks/locks";
import { NotificationsAPI } from "./notifications/notifications";
import { OfficiesAPI } from "./officies/officies";
import { RentersAPI } from "./renters/renters";
import { SharedLocksAPI } from "./sharedLocks/sharedLocks";
import { TicketsAPI } from "./tickets/tickets";
import { UsersAPI } from "./users/user";

const baseUrl = "https://api.elasticwork.space:" + getAPIPort() + "/client";

export const api = {
  auth: AuthAPI(baseUrl),
  billings: BillingsAPI(baseUrl),
  locations: LocationsAPI(baseUrl),
  locks: LocksAPI(baseUrl),
  sharedLocks: SharedLocksAPI(baseUrl),
  officies: OfficiesAPI(baseUrl),
  renters: RentersAPI(baseUrl),
  users: UsersAPI(baseUrl),
  tickets: TicketsAPI(baseUrl),
  notifications: NotificationsAPI(baseUrl)
}