import { useEffect, useState, useRef } from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa6';
import { useLocation, useNavigate } from 'react-router-dom';
import { Breadcrumbs } from './breadcrumbs';
import { Button } from '@nextui-org/react';

export const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const [path, setPath] = useState<string>("");
  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoForward, setCanGoForward] = useState(false);

  const forwardHistory = useRef<string[]>([]);

  useEffect(() => {
    setPath(location.pathname);
    setCanGoBack(window.history.length > 1);
    setCanGoForward(forwardHistory.current.length > 0);
  }, [location.pathname]);

  const goBack = () => {
    if (canGoBack) {
      forwardHistory.current.push(location.pathname);
      navigate(-1);
    }
  };

  const goForward = () => {
    if (forwardHistory.current.length > 0) {
      const nextPath = forwardHistory.current.pop()!;
      navigate(nextPath, { replace: true });

      setCanGoForward(forwardHistory.current.length > 0);
    }
  };

  return (
    <div className='flex flex-row gap-2 items-center'>
      <Button variant='flat' size='sm' color='default' onClick={goBack} isDisabled={!canGoBack}>
        <FaArrowLeft className='text-gray-500' />
      </Button>
      <Breadcrumbs path={path} />
      <Button variant='flat' size='sm' color='default' onClick={goForward} isDisabled={!canGoForward}>
        <FaArrowRight className='text-gray-500' />
      </Button>
    </div>
  );
};