import { Input } from "@nextui-org/react";
import { FaPaperPlane, FaTrash } from "react-icons/fa6";

export function AssistantPage() {
  return (
    <div className="flex flex-row py-2 gap-2 h-full max-h-[calc(100vh-12%)]">
      <div className="flex flex-col bg-white dark:bg-zinc-800 h-full gap-2 w-64 items-center py-4 px-4 rounded-md max-h-[calc(100vh-12%)]">
        <div className="flex flex-row cursor-pointer items-center w-full py-2 px-4 transition-all hover:bg-primary hover:text-white rounded-md">
          <span className="text-medium">Как сделать то-то то-то...</span>
        </div>
        <div className="flex flex-row cursor-pointer items-center w-full py-2 px-4 transition-all hover:bg-primary hover:text-white rounded-md">
          <span className="text-medium">Как сделать то-то то-то...</span>
        </div>
        <div className="flex flex-row cursor-pointer items-center w-full py-2 px-4 transition-all hover:bg-primary hover:text-white rounded-md">
          <span className="text-medium">Как сделать то-то то-то...</span>
        </div>
        <div className="flex flex-row cursor-pointer items-center w-full py-2 px-4 transition-all hover:bg-primary hover:text-white rounded-md">
          <span className="text-medium">Как сделать то-то то-то...</span>
        </div>
        <div className="flex flex-row cursor-pointer items-center w-full py-2 px-4 transition-all hover:bg-primary hover:text-white rounded-md">
          <span className="text-medium">Как сделать то-то то-то...</span>
        </div>
        <div className="flex flex-row cursor-pointer items-center w-full py-2 px-4 transition-all hover:bg-primary hover:text-white rounded-md">
          <span className="text-medium">Как сделать то-то то-то...</span>
        </div>
        <div className="flex flex-row cursor-pointer items-center w-full py-2 px-4 transition-all hover:bg-primary hover:text-white rounded-md">
          <span className="text-medium">Как сделать то-то то-то...</span>
        </div>
        <div className="flex flex-row cursor-pointer items-center w-full py-2 px-4 transition-all hover:bg-primary hover:text-white rounded-md">
          <span className="text-medium">Как сделать то-то то-то...</span>
        </div>
      </div>
      <div className="flex flex-col gap-4 w-full h-full justify-center items-center">
        <div className="flex flex-row gap-2 bg-white dark:bg-zinc-800 px-6 py-3 rounded-md items-center justify-center">
          <span className="font-semibold text-lg">Как сделать то-то то-то...</span>
          <FaTrash />
        </div>
        <div className="flex flex-col h-full gap-2 max-h-[calc(100vh-30%)] overflow-auto w-full items-center">
          <div className="flex flex-col gap-2 items-start w-full max-w-[820px]">
            <span className="text-primary font-semibold">Луиза</span>
            <div className="bg-white dark:bg-zinc-800 px-4 py-2 rounded-md">Здравствуйте! Чем я могу вам помочь?</div>
          </div>
          <div className="flex flex-col gap-2 items-end w-full max-w-[820px]">
            <span className="text-foreground-400 font-semibold">Вы</span>
            <div className="bg-white dark:bg-zinc-800 px-4 py-2 rounded-md">Здравствуйте! Чем я могу вам помочь?</div>
          </div>
          <div className="flex flex-col gap-2 items-start w-full max-w-[820px]">
            <span className="text-primary font-semibold">Луиза</span>
            <div className="bg-white dark:bg-zinc-800 px-4 py-2 rounded-md">Здравствуйте! Чем я могу вам помочь?</div>
          </div>
          <div className="flex flex-col gap-2 items-end w-full max-w-[820px]">
            <span className="text-foreground-400 font-semibold">Вы</span>
            <div className="bg-white dark:bg-zinc-800 px-4 py-2 rounded-md">Здравствуйте! Чем я могу вам помочь?</div>
          </div>
          <div className="flex flex-col gap-2 items-start w-full max-w-[820px]">
            <span className="text-primary font-semibold">Луиза</span>
            <div className="bg-white dark:bg-zinc-800 px-4 py-2 rounded-md">Здравствуйте! Чем я могу вам помочь?</div>
          </div>
          <div className="flex flex-col gap-2 items-end w-full max-w-[820px]">
            <span className="text-foreground-400 font-semibold">Вы</span>
            <div className="bg-white dark:bg-zinc-800 px-4 py-2 rounded-md">Здравствуйте! Чем я могу вам помочь?</div>
          </div>
          <div className="flex flex-col gap-2 items-start w-full max-w-[820px]">
            <span className="text-primary font-semibold">Луиза</span>
            <div className="bg-white dark:bg-zinc-800 px-4 py-2 rounded-md">Здравствуйте! Чем я могу вам помочь?</div>
          </div>
          <div className="flex flex-col gap-2 items-end w-full max-w-[820px]">
            <span className="text-foreground-400 font-semibold">Вы</span>
            <div className="bg-white dark:bg-zinc-800 px-4 py-2 rounded-md">Здравствуйте! Чем я могу вам помочь?</div>
          </div>
          <div className="flex flex-col gap-2 items-start w-full max-w-[820px]">
            <span className="text-primary font-semibold">Луиза</span>
            <div className="bg-white dark:bg-zinc-800 px-4 py-2 rounded-md">Здравствуйте! Чем я могу вам помочь?</div>
          </div>
          <div className="flex flex-col gap-2 items-end w-full max-w-[820px]">
            <span className="text-foreground-400 font-semibold">Вы</span>
            <div className="bg-white dark:bg-zinc-800 px-4 py-2 rounded-md">Здравствуйте! Чем я могу вам помочь?</div>
          </div>
          <div className="flex flex-col gap-2 items-start w-full max-w-[820px]">
            <span className="text-primary font-semibold">Луиза</span>
            <div className="bg-white dark:bg-zinc-800 px-4 py-2 rounded-md">Здравствуйте! Чем я могу вам помочь?</div>
          </div>
          <div className="flex flex-col gap-2 items-end w-full max-w-[820px]">
            <span className="text-foreground-400 font-semibold">Вы</span>
            <div className="bg-white dark:bg-zinc-800 px-4 py-2 rounded-md">Здравствуйте! Чем я могу вам помочь?</div>
          </div>
          <div className="flex flex-col gap-2 items-start w-full max-w-[820px]">
            <span className="text-primary font-semibold">Луиза</span>
            <div className="bg-white dark:bg-zinc-800 px-4 py-2 rounded-md">Здравствуйте! Чем я могу вам помочь?</div>
          </div>
          <div className="flex flex-col gap-2 items-end w-full max-w-[820px]">
            <span className="text-foreground-400 font-semibold">Вы</span>
            <div className="bg-white dark:bg-zinc-800 px-4 py-2 rounded-md">Здравствуйте! Чем я могу вам помочь?</div>
          </div>
          <div className="flex flex-col gap-2 items-start w-full max-w-[820px]">
            <span className="text-primary font-semibold">Луиза</span>
            <div className="bg-white dark:bg-zinc-800 px-4 py-2 rounded-md">Здравствуйте! Чем я могу вам помочь?</div>
          </div>
          <div className="flex flex-col gap-2 items-end w-full max-w-[820px]">
            <span className="text-foreground-400 font-semibold">Вы</span>
            <div className="bg-white dark:bg-zinc-800 px-4 py-2 rounded-md">Здравствуйте! Чем я могу вам помочь?</div>
          </div>
          <div className="flex flex-col gap-2 items-start w-full max-w-[820px]">
            <span className="text-primary font-semibold">Луиза</span>
            <div className="bg-white dark:bg-zinc-800 px-4 py-2 rounded-md">Здравствуйте! Чем я могу вам помочь?</div>
          </div>
          <div className="flex flex-col gap-2 items-end w-full max-w-[820px]">
            <span className="text-foreground-400 font-semibold">Вы</span>
            <div className="bg-white dark:bg-zinc-800 px-4 py-2 rounded-md">Здравствуйте! Чем я могу вам помочь?</div>
          </div>
        </div>
        <div className="flex flex-row gap-2 px-4 py-2 rounded-md items-center justify-center min-w-[350px] w-[50%]">
          <Input
            placeholder="Сообщение..."
            endContent={<FaPaperPlane className="cursor-pointer" />}
            variant="bordered"
            className="rounded-md w-full"
            />
        </div>
      </div>
    </div>
  )
}