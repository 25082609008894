import { Button, Checkbox, Input, Link } from "@nextui-org/react";
import FileUpload from "../../components/fileUpload/fileUpload";
import { Controller, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { api } from "../../../api";
import { toast } from "react-toastify";
import { useAppSelector } from "../../../hooks/useAppSelector";

export function SettingsPage({ displayTitle = true }: { displayTitle?: boolean }) {
  const personalInfoForm = useForm();
  const passwordForm = useForm();
  const fileUploadForm = useForm();
  const [passwordError, setPasswordError] = useState<string | null>(null);

  const user = useAppSelector(state => state.user.currentUser);

  const onSubmitPersonalInfo = (data: any) => {
    api.auth.updateInfo({
      first_name: data.firstName,
      last_name: data.lastName,
      username: data.username,
      phone_number: data.phoneNumber
    })
      .then(_res => {
        toast.success("Данные успешно обновлены!");
      })
      .catch(err => {
        toast.error("Произошла ошибка при обновлении данных.");
        console.error(err);
      });
  };

  const onSubmitPasswordChange = (data: any) => {
    if (data.newPassword !== data.confirmPassword) {
      setPasswordError("Пароли не совпадают");
    } else {
      setPasswordError(null);
      api.auth.updateInfo({
        password: data.newPassword
      })
        .then(_res => {
          toast.success("Пароль успешно обновлен!");
        })
        .catch(err => {
          toast.error("Произошла ошибка при обновлении пароля.");
          console.error(err);
        });
    }
  };

  const onSubmitFileUpload = (data: any) => {
    console.log("File Upload:", data);
  };

  useEffect(() => {
    if (user) {
      personalInfoForm.setValue("firstName", user.first_name || '');
      personalInfoForm.setValue("lastName", user.last_name || '');
      personalInfoForm.setValue("username", user.username || '');
      personalInfoForm.setValue("phoneNumber", user.phone_number || '');
    }
  }, [user, personalInfoForm]);

  return (
    <div className={`${displayTitle == true ? 'p-4' : ''}`}>
      {displayTitle == true ? (
        <div className="flex flex-row justify-between w-full items-center mb-4">
          <div className="flex flex-row gap-4 items-center">
            <span className="font-semibold text-2xl">Настройки</span>
          </div>
        </div>
      ) : null}

      <div className="flex max-md:flex-col md:flex-row gap-4">
        <div className="flex flex-col gap-4 w-full">
          <form onSubmit={personalInfoForm.handleSubmit(onSubmitPersonalInfo)} className="flex flex-col p-4 gap-4 bg-white dark:bg-zinc-900 rounded-xl border-2 border-foreground-200 dark:border-foreground-100">
            <div className="flex flex-row justify-between items-center">
              <span className="font-medium">Персональная информация</span>
              <Button
                type="submit"
                color="primary"
                variant="solid"
                size="sm"
              >
                Сохранить
              </Button>
            </div>

            <div className="flex flex-row items-center gap-4">
              <Controller
                control={personalInfoForm.control}
                name="firstName"
                defaultValue=""
                render={({ field }) => (
                  <Input
                    label="Имя"
                    variant="bordered"
                    placeholder="Ваше имя"
                    {...field}
                  />
                )}
              />
              <Controller
                control={personalInfoForm.control}
                name="lastName"
                defaultValue=""
                render={({ field }) => (
                  <Input
                    label="Фамилия"
                    variant="bordered"
                    placeholder="Ваша фамилия"
                    {...field}
                  />
                )}
              />
            </div>
            <div className="flex flex-row items-center gap-4">
              <Controller
                control={personalInfoForm.control}
                name="phoneNumber"
                defaultValue=""
                render={({ field }) => (
                  <Input
                    label="Номер телефона"
                    variant="bordered"
                    placeholder="Ваш номер телефона"
                    {...field}
                  />
                )}
              />
            </div>
            <div className="flex flex-row items-center gap-4">
              <Controller
                control={personalInfoForm.control}
                name="username"
                defaultValue=""
                render={({ field }) => (
                  <Input
                    label="Имя пользователя"
                    variant="bordered"
                    placeholder="Ваше имя пользователя"
                    {...field}
                  />
                )}
              />
            </div>
            <div className="flex flex-row items-center gap-4">
              <Controller
                control={personalInfoForm.control}
                name="noNotifications"
                defaultValue={false}
                render={({ field }) => (
                  <Checkbox {...field}>
                    Не оповещайте меня о уведомлениях
                  </Checkbox>
                )}
              />
            </div>
          </form>

          <form onSubmit={passwordForm.handleSubmit(onSubmitPasswordChange)} className="flex flex-col p-4 gap-4 bg-white dark:bg-zinc-900 rounded-xl border-2 border-foreground-200 dark:border-foreground-100">
            <div className="flex flex-row justify-between items-center">
              <span className="font-medium">Смена пароля</span>
              <Button
                type="submit"
                color="primary"
                variant="solid"
                size="sm"
              >
                Сохранить
              </Button>
            </div>

            <div className="flex flex-row items-center gap-4">
              <Input
                label="Новый пароль"
                variant="bordered"
                placeholder="Введите ваш новый пароль"
                {...passwordForm.register("newPassword")}
              />
            </div>
            <div className="flex flex-row items-center gap-4">
              <Input
                label="Новый пароль (повторно)"
                variant="bordered"
                placeholder="Введите ваш новый пароль ещё раз"
                {...passwordForm.register("confirmPassword")}
              />
            </div>
            {passwordError && (
              <span className="text-red-500">{passwordError}</span>
            )}
          </form>
        </div>

        <div className="flex flex-col gap-4">
          <form onSubmit={fileUploadForm.handleSubmit(onSubmitFileUpload)} className="flex flex-col p-4 gap-4 bg-white dark:bg-zinc-900 rounded-xl border-2 border-foreground-200 dark:border-foreground-100">
            <div className="flex flex-row justify-between items-center">
              <span className="font-medium">Фото профиля</span>
            </div>

            <div className="flex flex-row justify-start items-center gap-4">
              <div className="bg-gray-500 min-h-16 min-w-16 rounded-full block" />
              <div className="flex flex-col gap-1">
                <span>Это ваше текущее фото профиля</span>
                <span className="font-medium text-red-500 cursor-pointer">Удалить</span>
              </div>
            </div>

            <div className="flex flex-row items-center gap-4">
              <FileUpload
                control={fileUploadForm.control}
                name="avatar_file"
              />
            </div>

            <Button
              type="submit"
              color="primary"
              variant="solid"
              size="sm"
            >
              Загрузить
            </Button>
          </form>

          <div className="flex flex-col p-4 gap-4 bg-white dark:bg-zinc-900 rounded-xl border-2 border-foreground-200 dark:border-foreground-100">
            <div className="flex flex-row justify-between items-center">
              <span className="font-medium">Telegram</span>
              {user.telegram !== null && user.telegram !== 0 && typeof user.telegram !== undefined ? (
                <Button
                  color="success"
                  variant="flat"
                  size="sm"
                >
                  Привязан
                </Button>
              ) : (
                <Button
                  color="danger"
                  variant="flat"
                  size="sm"
                >
                  Не привязан
                </Button>
              )}
            </div>
            <span className="text-foreground-500">Используйте нашего Telegram бота для более быстрого и удобного доступа к функциям личного кабинета.</span>
            <Link showAnchorIcon href="#">Узнать больше</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
