import { TableBuilder } from "../../../components/tables/tableBuilder";
import { useEffect, useState } from "react";
import { api } from "../../../../api";
import { toast } from "react-toastify";
import { User } from "../../../../api/auth/auth";
import { FaArrowRightToBracket, FaExpand, FaGears, FaPencil, FaPlus, FaUserPlus } from "react-icons/fa6";
import { Button, useDisclosure } from "@nextui-org/react";
import { CreateUserModal } from "../../../components/modals/users/createUserModal";
import { UpdateUserModal } from "../../../components/modals/users/updateUserModal";
import { useNavigate } from "react-router-dom";
import { InviteEmployeeModal } from "../../../components/modals/users/inviteUserModal";
import { ColumnType } from "../../../components/tables/tableTypes";

export function UsersPage() {
  const [users, setUsers] = useState<User[]>([]);
  const [user, setUser] = useState<User | null>(null);
  const navigate = useNavigate();

  const createUserModalDisclosure = useDisclosure();
  const editUserModalDisclosure = useDisclosure();
  const inviteUserModalDisclosure = useDisclosure();

  const fullscreenDisclosure = useDisclosure();

  const editUser = (user: User) => {
    setUser(user);
    editUserModalDisclosure.onOpen();
  };

  useEffect(() => {
    api.renters.listEmployees()
      .then(data => {
        setUsers(data.data);
      })
      .catch(err => {
        console.log(err);
        toast.error("Произошла ошибка при загрузке пользователей!");
      });
  }, [
    createUserModalDisclosure.isOpen,
    editUserModalDisclosure.isOpen,
    inviteUserModalDisclosure.isOpen
  ]);

  return (
    <>
      <CreateUserModal disclosure={createUserModalDisclosure} />
      <UpdateUserModal disclosure={editUserModalDisclosure} user={user} />
      <InviteEmployeeModal disclosure={inviteUserModalDisclosure} />
      <div className="flex flex-row flex-wrap gap-4 w-full">
        <div className="flex-grow bg-white dark:bg-zinc-900 rounded-xl border-2 border-foreground-200 dark:border-foreground-100 p-4">
          <div className="flex flex-row justify-between w-full items-center mb-4">
            <div className="flex flex-row gap-4 items-center">
              <span className="font-medium">Сотрудники</span>
              <Button variant="flat" size="sm" color="default" onClick={fullscreenDisclosure.onOpen}><FaExpand /></Button>
            </div>
            <div className="flex flex-row gap-4 items-center">
              <Button
                startContent={<FaUserPlus />}
                color="primary"
                variant="bordered"
                className="max-w-fit"
                onClick={() => inviteUserModalDisclosure.onOpen()}
              >
                Пригласить
              </Button>
              <Button
                startContent={<FaPlus />}
                color="primary"
                variant="solid"
                className="max-w-fit"
                onClick={() => createUserModalDisclosure.onOpen()}
              >
                Создать пользователя
              </Button>
            </div>
          </div>
          <div className="flex flex-col gap-2 mb-2">
            <TableBuilder
              fullscreenDisclosure={fullscreenDisclosure}
              removeWrapper
              displayOptions={false}
              columns={[
                {
                  label: "Имя пользователя",
                  key: "username",
                  type: ColumnType.String
                }, {
                  label: "ФИО",
                  key: "names",
                  type: ColumnType.Custom,
                  render(_value, row: User) {
                    return (<span>{row.first_name}{row.middle_name ? ` ${row.middle_name} ` : ''}{row.last_name}</span>)
                  },
                }, {
                  label: "Номер телефона",
                  key: "phone_number",
                  type: ColumnType.String
                }, {
                  label: "Telegram",
                  key: "telegram",
                  type: ColumnType.Custom,
                  render(value, _row) {
                    return (<span>{value == 0 || value == null ? "Не привязан" : "Привязан"}</span>);
                  },
                }, {
                  key: "actions",
                  label: "Действия",
                  type: ColumnType.Actions,
                  actions: [
                    {
                      icon: <FaArrowRightToBracket />,
                      onClick: (_value, row) => { navigate("/dashboard/users/" + row.id) }
                    },
                    {
                      icon: <FaPencil />,
                      onClick: (_value, row) => { editUser(row) }
                    },
                    {
                      icon: <FaGears />,
                      onClick: (_value, row) => { navigate("/dashboard/users/" + row.id + "/roles") }
                    }
                  ]
                }
              ]}
              data={users || []}
              rowsPerPage={10}
            />
          </div>
        </div>
      </div>
    </>
  )
}